import {ClipboardModule} from '@angular/cdk/clipboard';
import {FlexLayoutModule} from '@angular/flex-layout';
import {ReactiveFormsModule} from '@angular/forms';
import {MatInputModule} from '@angular/material/input';

import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {CommonModule} from '@angular/common';
import {HttpClientModule} from "@angular/common/http";
import {MatBottomSheetModule} from "@angular/material/bottom-sheet";
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  AngularAxiosRulenHttpService,
  AutoFormComponent,
  AutoFormModule,
  ClientConfigModule,
  DemoCommonModule,
  IVDemoConfig,
  IV_DEMO_CONFIG,
  IlluinVoiceFormsModule,
  RULEN_HTTP_SERVICE
} from "@illuin-voice/angular";
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {CloseButtonComponent} from './illuin-voice-app/close-button/close-button.component';
import { GoBackToSalesforceMessageComponent } from "./illuin-voice-app/go-back-to-salesforce-message/go-back-to-salesforce-message.component";
import { HeadingsComponent } from "./illuin-voice-app/headings/headings.component";

@NgModule({
  declarations: [
    AppComponent,
    CloseButtonComponent,
    GoBackToSalesforceMessageComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    DemoCommonModule,
    AppRoutingModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatSnackBarModule,
    AutoFormModule,
    MatBottomSheetModule,
    IlluinVoiceFormsModule,
    HttpClientModule,
    ClipboardModule,
    ClientConfigModule,
  ],
  providers: [
    {
      provide: IV_DEMO_CONFIG,
      useFactory: () =>
        ({
          formComponent: AutoFormComponent,
          formButtonComponent: CloseButtonComponent,
          // formButtonComponent: GoBackToSalesforceMessageComponent,
          recordingHeadingsComponent: HeadingsComponent,
          // formButtonComponent: DefaultFormButtonComponent,
          mobile: true,
          hideHeadingsOnStart: true,
        } as IVDemoConfig),
    },
    {
      provide: RULEN_HTTP_SERVICE,
      useClass: AngularAxiosRulenHttpService,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
