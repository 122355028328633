import {IlluinVoiceClientConfig, RulenValidation} from "@illuin-voice/front";

const BASE_CONFIGURATION: Partial<IlluinVoiceClientConfig> = {
    validationConfig:
        {
          type: 'manual',
          emitState: true
        } as RulenValidation,
        rulenApiWsOrigin: "${RULEN_API_WS_ORIGIN}",
        rulenApiWsPath: "${RULEN_API_WS_PATH}",
        sttApiWsOrigin: "${STT_API_WS_ORIGIN}",
        sttApiWsPath: "${STT_API_WS_PATH}",
        rulenApiOrigin: "${RULEN_API_ORIGIN}",
        rulenApiCustomHeaders: {"Authorization": "Basic ${RULEN_API_AUTHORIZATION_BEARER_TOKEN}"},
        rulenApiKey: "${RULEN_API_KEY}",
        mediaRecorderConstraints: {
          video: false,
          audio: {
            echoCancellation: false,
            noiseSuppression: false,
            autoGainControl: false
          }
        } as MediaTrackConstraints,
  }

export const generateConfiguration = (
    runtimeId: string,
    documentId: string | null,
  ) => {
    const config = {
      ...BASE_CONFIGURATION,
      runtimeId: runtimeId,
      documentId: documentId,
    } as IlluinVoiceClientConfig;
    return config;
  }