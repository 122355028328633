import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import {
  ClientConfigModule,
  DefaultTTSService,
  IV_CLIENT_CONFIG,
  IV_CLIENT_CONFIG_INTERNAL,
  RULEN_TTS_SERVICE,
} from "@illuin-voice/angular";
import { defaultIlluinVoiceConfigFactory } from "@illuin-voice/front";
import { generateConfiguration } from "./config";
import {REDIRECT_URL} from "./illuin-voice-app/close-button/close-button.component";

const routes: Routes = [
  {
    path: "",
    providers: [
      {
        provide: IV_CLIENT_CONFIG,
        useFactory: () => {
          // Retrieve the query params from the URL
          const queryParams = new URLSearchParams(window.location.search);
          const config = generateConfiguration(
            queryParams.get("runtimeId") ?? "",
            queryParams.get("documentId") ?? ""
          );
          if (queryParams.get("rulenApiKey")) {
            config.rulenApiKey = queryParams.get("rulenApiKey") ?? "";
          }
          return config;
        },
      },
      {
        provide: REDIRECT_URL,
        useFactory: () => {
          // Retrieve the query params from the URL
          const queryParams = new URLSearchParams(window.location.search);
          return queryParams.get("redirectUrl") ?? "";
        },
      },
      {
        provide: IV_CLIENT_CONFIG_INTERNAL,
        useFactory: (config: any) => {
          console.log("CONFIG", config);
          return defaultIlluinVoiceConfigFactory(config);
        },
        deps: [IV_CLIENT_CONFIG],
      },
      // TODO: Add this to get the TTS working
      // {
      //   provide: RULEN_TTS_SERVICE,
      //   useClass: DefaultTTSService,
      // },
      // Using the ClientConfigModule.providers
      ...ClientConfigModule.providers,
    ],
    children: [
      {
        path: "desktop",
        loadChildren: () =>
          import("@illuin-voice/angular").then((e) => e.DemoDesktopModule),
      },
      {
        path: "mobile",
        loadChildren: () =>
          import("@illuin-voice/angular").then((e) => e.DemoMobileModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
