import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-headings',
  templateUrl: './headings.component.html',
  styleUrls: ['./headings.component.scss']
})
export class HeadingsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
