import { Component, Inject, InjectionToken, OnInit } from '@angular/core';


export const REDIRECT_URL = new InjectionToken<string>('REDIRECT_URL');

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss']
})
export class CloseButtonComponent implements OnInit {

  constructor(
    @Inject(REDIRECT_URL) public redirectUrl: string
  ) { }

  ngOnInit(): void {
  }

  public closeFrontAndGoBackToSalesforce(){
    window.open(this.redirectUrl, "_self")
    window.close();
  }
}
